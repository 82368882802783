import { css } from 'styled-components'
import { linkStyle } from './linkStyle'

export const linkStyleGrey = () => css`
  ${linkStyle}
  color: var(--n200);
  &:hover {
    background-color: var(--quip-link-grey-bg);
  }
  &:focus,
  &:active {
    border-color: var(--n200);
  }
`
