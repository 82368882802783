/* eslint-disable no-bitwise */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/_variables.css'
import './src/styles/normalize.css'
import './src/styles/global.css'
import 'react-loading-skeleton/dist/skeleton.css'

import type { GatsbyBrowser } from 'gatsby'
import { rootProviders } from './gatsby-providers'
import { createOptimizelyInstance } from './src/services/optimizely'
import { pageView, productPageViewEvent } from './src/analytics'
import scrollToAnchor from './src/services/utils/scrollToAnchor'
import pixels from './src/pixels'
import { SiteConfig } from './site-config'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = rootProviders

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  // onRouteUpdate is executed even on first page load, but the loading of the pixels in the head tag already fires
  // a page view event. We only want pixel updates to fire when they navigated from somewhere else SPA-style so
  // this is why we check prevLocation.
  if (prevLocation && SiteConfig.pixelsEnabled) {
    pixels.keys().forEach(moduleName => {
      const { onRouteUpdate: pixelOnRouteUpdate, disabled } = pixels(moduleName)
      if (pixelOnRouteUpdate && !disabled) {
        pixelOnRouteUpdate({ location, prevLocation })
      }
    })
  }
  // // Segment page view
  if (prevLocation) {
    pageView()
  }
  // Optimizely track event
  if (location?.pathname?.startsWith('/store/products') || location?.pathname?.startsWith('/refills/products')) {
    const optimizelyPageViewAsync = async () => {
      const optimizelyInstance = createOptimizelyInstance({ setUser: true })
      const { success } = await optimizelyInstance.onReady()
      if (success) optimizelyInstance.track('productPageViewed')
    }
    void optimizelyPageViewAsync()
    productPageViewEvent()
  }

  // Gatsby Link's don't work well with hash's so if the Route has changed and there is an anchor, we scroll to it.
  // A small delay is added to ensure the anchored element is in the DOM.
  if (location.hash) {
    setTimeout(() => scrollToAnchor(location.hash), 200)
  }
}
