import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { CurrentUserProvider } from './src/contexts/CurrentUserContext'
import ApolloClientProvider from './src/apollo/ApolloClientProvider'
import ReduxProvider from './src/redux'
import { QuiponentsProvider } from './src/quiponents'
import { OptimizelyClientProvider } from './src/services/optimizely'

export const rootProviders: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <OptimizelyClientProvider>
    <ApolloClientProvider>
      <ReduxProvider>
        <CurrentUserProvider>
          <QuiponentsProvider>{element}</QuiponentsProvider>
        </CurrentUserProvider>
      </ReduxProvider>
    </ApolloClientProvider>
  </OptimizelyClientProvider>
)
