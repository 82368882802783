import React, { FC } from 'react'
import styled from 'styled-components'

const StyledModalImage = styled.img``

const ModalImage: FC<ModalImageProps> = ({ style = {}, src, alt }) => (
  <StyledModalImage style={style} src={src} alt={alt} />
)

export default ModalImage
