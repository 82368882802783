import { useState, useEffect } from 'react'
import omit from 'lodash/omit'
import { useSelector } from 'react-redux'

const ALL_STEPS = {
  AGE: 'ageStep',
  PURCHASE_LOCATION: 'purchaseLocationStep',
  PRODUCTS: 'productsStep',
  COLOR: 'colorStep',
  REFILL_START: 'refillStartStep',
  COUPON: 'couponStep',
}

function useSteps(wizPreviouslyCompleted: boolean) {
  const cartCoupon = useSelector((state: ReduxState) => state.cart.coupon)
  const purchaseLocation = useSelector((state: ReduxState) => state.refillWizard.purchaseLocationStep)
  const [steps, setSteps] = useState<UseStepsState>(ALL_STEPS)

  useEffect(() => {
    if (wizPreviouslyCompleted) {
      setSteps(omit(ALL_STEPS, ['PURCHASE_LOCATION', 'REFILL_START', 'COUPON']))
    }

    if ((cartCoupon?.code || purchaseLocation === 'quip') && !wizPreviouslyCompleted) {
      setSteps(omit(ALL_STEPS, ['COUPON']))
    }
  }, [wizPreviouslyCompleted, cartCoupon, purchaseLocation])

  return {
    steps: Object.values(steps),
    totalNumOfSteps: Object.values(steps).length,
  }
}

export { useSteps, ALL_STEPS }
