import React, { FC, useMemo } from 'react'
import styled from 'styled-components'

const Footer = styled.div`
  background-color: ${({ theme }) => theme.footerBackgroundColor || theme.backgroundColor};
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 100%;
  text-align: center;
  border-radius: 0 0 0.625rem 0.625rem;
`

const ModalFooter: FC<ModalFooterProps> = ({ children, modalid, style = {} }) => {
  const footerChildren = useMemo(
    () =>
      React.Children.map(children, child => {
        if (typeof child !== 'string' && !!child) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return React.cloneElement(child as React.ReactElement<any>, {
            modalid,
          })
        }
        return child
      }),
    [modalid]
  )

  return <Footer style={style}>{footerChildren}</Footer>
}

export default ModalFooter
