import React from 'react'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

const ReduxProvider = ({ children }: { children: React.ReactNode }) => {
  // Redux DevTools Extension is disabled for production
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [],
  })
  return <Provider store={store}>{children}</Provider>
}
export default ReduxProvider
