import path from 'path'

export const SiteConfig = {
  title: 'quip',
  siteTitleShort: 'quip',
  description: 'Better oral care, made simple',
  author: 'getquip.com',
  siteUrl: process.env.GATSBY_SITE_URL || 'https://www.getquip.com',
  themeColor: '#000',
  backgroundColor: '#fff',
  pathPrefix: null,
  logo: path.resolve('src/images/icon.png'),
  social: {
    twitter: '@getquip',
    fbAppId: '',
    facebookShareUrlPrefix: 'https://www.facebook.com/share.php?u=',
    twitterShareUrlPrefix: 'https://twitter.com/intent/tweet?text=',
  },
  pixelsEnabled: process.env.NODE_ENV === 'production',
  blog: {
    postUrlPrefix: '/blog',
    categoryListUrlPrefix: '/blog/categories',
  },
}
