module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"logs":{"clientToken":"pubf503785c0e018c9d0cad995e3a824b09","enabled":true,"env":"staging","forwardConsoleLogs":"all","forwardErrorsToLogs":true,"sampleRate":100,"site":"datadoghq.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"quip | Better oral care, made simple","short_name":"quip","start_url":"/","background_color":"#50FCCE","theme_color":"#50FCCE","display":"minimal-ui","icon":"static/favicons/quip-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d0efbc7151eae4e64d785c59d4a546c8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WKZRKF4","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"()=>window.dataLayer||[]"},"gtmAuth":"Yx7pt3YxXvl7-9eiTB4XXw","gtmPreview":"env-188","dataLayerName":"dataLayer","routeChangeEventName":"site-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
