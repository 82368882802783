/* eslint-disable react/no-danger */
import React, { useEffect } from 'react'
import { oneLine } from 'common-tags'
import useCurrentUser from '../contexts/CurrentUserContext/useCurrentUser'

declare global {
  interface Window {
    snaptr: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (...args: any[]): void
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pixelIdList: any[]
    }
  }
}

function alreadyInitialized() {
  return window.snaptr.pixelIdList && window.snaptr.pixelIdList.length
}

export const disabled = false

export function DynamicPixel() {
  const { loadingCurrentUser, currentUser } = useCurrentUser()

  useEffect(() => {
    if (typeof window.snaptr === 'function' && !loadingCurrentUser) {
      const userProperties =
        currentUser && !currentUser.guest ? { user_hashed_email: currentUser.sha256HashedEmail } : {}

      window.snaptr('init', '82869919-e74f-4511-80a2-bf06a2b8bc7a', userProperties)
      window.snaptr('init', '2145c447-d41a-4000-aba1-684d8715f946', userProperties)
      window.snaptr('track', 'PAGE_VIEW')
    }
  }, [loadingCurrentUser])

  return null
}

export const scriptsForHeadTag = [
  <script
    async
    dangerouslySetInnerHTML={{
      __html: oneLine`
      (function(win, doc, sdk_url){
        if(win.snaptr) return;
        var tr=win.snaptr=function(){
          tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);
        };
        tr.queue = [];
        var s='script';
        var new_script_section=doc.createElement(s);
        new_script_section.async=!0;
        new_script_section.src=sdk_url;
        var insert_pos=doc.getElementsByTagName(s)[0];
        insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
      })(window, document, 'https://sc-static.net/scevent.min.js');
      `,
    }}
  />,
]

export function onRouteUpdate() {
  if (typeof window.snaptr === 'function' && alreadyInitialized()) {
    window.snaptr('track', 'PAGE_VIEW')
  }
}
