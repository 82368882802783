import { createSlice } from '@reduxjs/toolkit'

// hydrate the store
export const emailCapturePopUpSlice = createSlice({
  name: 'emailCapturePopUp',
  initialState: {} as EmailCapturePopUpStore,
  reducers: {
    updateEmailCapturePopUp: (_state, action) => action.payload,
  },
})

const { actions, reducer } = emailCapturePopUpSlice
export const { updateEmailCapturePopUp } = actions
export default reducer
