import { css } from 'styled-components'

export const linkStyleBlack = () => css`
  color: var(--n1000);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
    color: var(--n700);
  }
  &[disabled] {
    color: var(--disabled, #f9f9f9);
    pointer-events: none;
  }
`
