export const SET_USER = 'SET_USER'
export const SET_LOADING = 'SET_LOADING'
export const SIGN_OUT = 'SIGN_OUT'
export const UPDATE_CART = 'ADD_TO_CART'

const INITIAL_STATE: CurrentUserState = { currentUser: null, loading: true }

export default function currentUserReducer(state = INITIAL_STATE, action: CurrentUserReduxAction) {
  switch (action.type) {
    case SET_USER: {
      return { ...state, currentUser: action.user }
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading }
    }
    case SIGN_OUT: {
      return { ...INITIAL_STATE }
    }
    case UPDATE_CART: {
      return { ...state, currentUser: { ...state.currentUser, cart: action.cart } }
    }
    default: {
      return state
    }
  }
}
