const DefaultTheme = {
  color: 'var(--n700)',
  backgroundColor: 'var(--n0)',
  closeIconPosition: 'right',
  closeButtonBackground: 'var(--quip-teal, #16B79D)',
  closeButtonText: 'var(--quip-sage, #0b413e) !important',
  closeButtonBorder: 'transparent',
  errorTextColor: 'var(--error-text)',
}

const GreenTheme = {
  color: 'var(--n0)',
  backgroundColor: 'var(--moss-dark)',
  closeIconPosition: 'right',
  closeButtonBackground: 'var(--teal)',
  closeButtonText: 'var(--n0, #fff)',
  closeButtonBorder: 'transparent',
  errorTextColor: 'var(--n0)',
}

const GrayTheme = {
  color: 'var(--n700)',
  backgroundColor: 'var(--n100)',
  footerBackgroundColor: 'var(--n0)',
  sectionColor: 'var(--n100)',
  closeIconPosition: 'left',
  closeButtonBackground: 'var(--moss-dark, #5a767a)',
  closeButtonText: 'var(--n0, #fff) !important',
  closeButtonBorder: 'transparent',
  errorTextColor: 'var(--error-text)',
}
// rename to theme
const THEMES: ModalThemeProps = {
  default: DefaultTheme,
  green: GreenTheme,
  gray: GrayTheme,
}

export default THEMES
