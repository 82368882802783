import { combineReducers } from 'redux'
import cartReducer from './cartSlice'
import cartTransactionReducer from './cartTransactionSlice'
import currentUserReducer from '../contexts/CurrentUserContext/currentUserReducer'
import psReducer from './productSelectorSlice'
import selectedProductsPricingReducer from './selectedProductsPricingSlice'
import refillWizardReducer from './refillWizardSlice'
import emailCapturePopUpReducer from './emailCapturePopUpSlice'

// Import all reducers here

const rootReducer = combineReducers({
  user: currentUserReducer,
  cart: cartReducer,
  transactions: cartTransactionReducer,
  productSelector: psReducer,
  selectedProductsPricing: selectedProductsPricingReducer,
  refillWizard: refillWizardReducer,
  emailCapturePopUp: emailCapturePopUpReducer,
})

export default rootReducer
