/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

// hydrate the store

export const cart = createSlice({
  name: 'cart',
  initialState: { isOpen: false, nextRefillCost: 0, subtotal: 0, coupon: null },
  reducers: {
    hydrateCart: (state, action) => {
      state.nextRefillCost = action.payload?.cartNextRefillCost
      state.subtotal = action.payload?.cartSubtotal
      state.coupon = action.payload?.coupon
    },
    updateCart: (state, action) => {
      state.nextRefillCost = action.payload?.cartNextRefillCost
      state.subtotal = action.payload?.cartSubtotal
      state.coupon = action.payload?.coupon
    },
    toggleOpenCart: (state, action) => {
      state.isOpen = action.payload
    },
  },
})

const { actions, reducer } = cart
export const { hydrateCart, updateCart, toggleOpenCart } = actions
export default reducer
