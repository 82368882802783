/* eslint-disable react/no-danger */
import React, { useEffect } from 'react'

import identity from 'lodash/identity'
import { oneLine } from 'common-tags'
import pickBy from 'lodash/pickBy'
import useCurrentUser from '../contexts/CurrentUserContext/useCurrentUser'

declare global {
  interface Window {
    fbq: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (...args: any[]): void
      getState: () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pixels: any[]
      }
    }
  }
}

export const disabled = true
export const scriptsForHeadTag = [
  <script
    async
    dangerouslySetInnerHTML={{
      __html: oneLine`
      !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
      n.push=n;n.loaded=!0;n.version="2.0";n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
      document,"script","//connect.facebook.net/en_US/fbevents.js");
      `,
    }}
  />,
]

function removeFalsyValuesFromObject(object: Record<string, string | null | undefined>) {
  return pickBy(object, identity)
}

function alreadyInitialized() {
  return typeof window.fbq.getState === 'function' && window.fbq.getState().pixels.length
}

export function DynamicPixel() {
  const { loadingCurrentUser, currentUser } = useCurrentUser()

  useEffect(() => {
    if (typeof window.fbq === 'function' && !alreadyInitialized() && !loadingCurrentUser) {
      const userProperties =
        currentUser && !currentUser.guest
          ? removeFalsyValuesFromObject({
              em: currentUser.email,
              fn: currentUser.firstName,
              ln: currentUser.lastName,
              ph: currentUser.phoneNumber,
            })
          : {}

      window.fbq('init', '1729444483949162', userProperties)
      window.fbq('track', 'PageView')
    }
  }, [loadingCurrentUser])

  return null
}

export function onRouteUpdate() {
  if (typeof window.fbq === 'function' && alreadyInitialized()) {
    window.fbq('track', 'PageView')
  }
}
