import React, { FC } from 'react'
import styled from 'styled-components'
import ModalCloseIcon from './ModalCloseIcon'

const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.headerBgColor || 'inherit'};
  color: ${({ theme }) => theme.color};
  border-radius: 0.625rem 0.625rem 0 0;
  height: 2.813rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
`

const IconWrapper = styled.div<{ iconPosition: string | null }>`
  margin-left: ${({ theme, iconPosition }) => (iconPosition || theme.closeIconPosition) === 'right' && 'auto'};
  margin-right: ${({ theme, iconPosition }) => (iconPosition || theme.closeIconPosition) === 'left' && 'auto'};
`

const ModalHeader: FC<ModalHeaderProps> = ({ iconPosition = null, modalid = '', style = {}, showCloseIcon = true }) => (
  <HeaderContainer style={style}>
    {showCloseIcon && (
      <IconWrapper iconPosition={iconPosition}>
        <ModalCloseIcon modalid={modalid} />
      </IconWrapper>
    )}
  </HeaderContainer>
)

export default ModalHeader
