import React, { FC, useMemo, useContext } from 'react'
import styled from 'styled-components'
import ModalContext from '../../context/Modal'
import Button from '../../../components/Button'

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.closeButtonText};
  background-color: ${({ theme }) => theme.closeButtonBackground};
  border-color: ${({ theme }) => theme.closeButtonBorder};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
  width: 16.5rem;
  height: 2.5rem;
  @media (min-width: 400px) {
    width: 20.5rem;
    height: 3.5rem;
  }
`

const ButtonLink = styled.a`
  color: ${({ theme }) => theme.closeButtonText};
  display: block;
  font-size: 0.9375rem;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  font-family: var(--font-family, 'Poppins', Helvetica, Arial, sans-serif) !important;
  text-align: center;
`

const ModalCloseButton: FC<ModalCloseButtonProps> = ({
  id = '',
  modalid = '',
  type = 'text',
  text = 'Close',
  beforeClose = () => {},
  style = {},
}) => {
  const { closeModal } = useContext(ModalContext)
  const handleClick = () => {
    beforeClose()
    closeModal(modalid)
  }

  const CTA = useMemo(() => {
    if (type === 'button') {
      return (
        <StyledButton id={id} onClick={handleClick} style={style}>
          {text}
        </StyledButton>
      )
    }

    return (
      <ButtonLink id={id} onClick={handleClick} style={style}>
        {text}
      </ButtonLink>
    )
  }, [type])

  return CTA
}

export default ModalCloseButton
