/* eslint-disable react/no-danger */
import React from 'react'
import { oneLine } from 'common-tags'

export const disabled = true

export const scriptsForBodyTag = [
  <script
    async
    dangerouslySetInnerHTML={{
      __html: oneLine`
      var _pix = document.getElementById('_pix_id_c008c8ba-d660-5c4c-5a68-59ab49691377');if (!_pix) { var protocol = '//'; var a = Math.random() * 1000000000000000000; _pix = document.createElement('iframe'); _pix.style.display = 'none'; _pix.setAttribute('src', protocol + 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Dc008c8ba-d660-5c4c-5a68-59ab49691377%26type%3DUNKNOWN%26m%3D1&ex-fch=416613&ex-src=https://www.getquip.com/&ex-hargs=v%3D1.0%3Bc%3D2623304890101%3Bp%3DC008C8BA-D660-5C4C-5A68-59AB49691377' + '&cb=' + a); _pix.setAttribute('id','_pix_id_c008c8ba-d660-5c4c-5a68-59ab49691377'); document.body.appendChild(_pix);}
      `,
    }}
  />,
  <noscript>
    <img
      height="0"
      width="0"
      alt=""
      src="https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Dc008c8ba-d660-5c4c-5a68-59ab49691377%26type%3DUNKNOWN%26m%3D1&ex-fch=416613&ex-src=https://www.getquip.com/&ex-hargs=v%3D1.0%3Bc%3D2623304890101%3Bp%3DC008C8BA-D660-5C4C-5A68-59AB49691377"
    />
  </noscript>,
]
