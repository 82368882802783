export function scrubQueryParametersFromUrl(unwantedParams: string[]): null {
  if (typeof window === 'undefined') return null

  const url = new URL(window.location.href)
  const searchParams = url.searchParams
  unwantedParams.forEach(unwantedParam => searchParams.delete(unwantedParam))
  const sanitizedSearchParams = searchParams.toString()

  let sanitizedPath = window.location.pathname
  if (sanitizedSearchParams !== '') {
    sanitizedPath = sanitizedPath + '?' + sanitizedSearchParams
  }
  sanitizedPath = sanitizedPath + url.hash

  window.history.replaceState(null, '', sanitizedPath)

  return null
}
