import React, { useEffect, createContext } from 'react'
import { useDispatch } from 'react-redux'
import { identify } from '../../analytics/events'
import { useCurrentUserQuery, CurrentUserQuery } from '../../generated/graphql'
import { hydrateCart } from '../../redux/cartSlice'
import { hydrateTransactions } from '../../redux/cartTransactionSlice'
import { SET_LOADING, SET_USER } from './currentUserReducer'

interface CurrentUserContextProps {
  currentUser: CurrentUserQuery['user']
  loadingCurrentUser: boolean
}

const CurrentUserContext = createContext<CurrentUserContextProps>({
  currentUser: undefined,
  loadingCurrentUser: true,
})

export default CurrentUserContext

export function CurrentUserProvider({ children }: { children: React.ReactNode }) {
  const { data, loading } = useCurrentUserQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: SET_LOADING, loading })
  }, [loading])

  useEffect(() => {
    dispatch({ type: SET_USER, user: data?.user })
    dispatch(hydrateCart(data?.user?.cart))
    dispatch(hydrateTransactions(data?.user?.cart?.cartTransactions))
  }, [data])

  if (data?.user && !data?.user?.guest) {
    identify(data?.user?.externalId, {
      name: data?.user?.name,
      email: data?.user?.email,
    })
  }

  return (
    <CurrentUserContext.Provider value={{ currentUser: data?.user, loadingCurrentUser: loading }}>
      {children}
    </CurrentUserContext.Provider>
  )
}
