/* eslint-disable react/no-danger */
import React, { useEffect } from 'react'
import { oneLine } from 'common-tags'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import useCurrentUser from '../contexts/CurrentUserContext/useCurrentUser'

const IR_COOKIE_NAME = 'irclickid'
const COOKIE_EXPIRATION_IN_DAYS = 30

declare global {
  interface Window {
    ire: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (...args: any[]): void
    }
  }
}

export const disabled = false

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setIRCookie(value: any) {
  const date = new Date()
  date.setTime(date.getTime() + COOKIE_EXPIRATION_IN_DAYS * 24 * 60 * 60 * 1000)

  document.cookie = `${IR_COOKIE_NAME}=${value};expires=${date.toUTCString()};path=/`
}

export function DynamicPixel() {
  const { loadingCurrentUser, currentUser } = useCurrentUser()
  const location = useLocation()
  const search = location.search ? queryString.parse(location.search) : {}
  const irclickid: string = (search.irclickid as string) || ''

  useEffect(() => {
    if (typeof window.ire === 'function' && !loadingCurrentUser) {
      let customerId = ''
      let customerEmail = ''
      if (currentUser && !currentUser.guest) {
        customerId = currentUser.externalId || ''
        customerEmail = currentUser.sha1HashedEmail || ''
      }

      window.ire('identify', {
        customerId,
        customerEmail,
      })

      if (irclickid) {
        setIRCookie(irclickid)
      }

      try {
        window.ire('generateClickId', (clickId: string) => {
          setIRCookie(clickId)
        })
      } catch (e) {
        // Only works in productions
      }
    }
  }, [loadingCurrentUser])

  return null
}

export const scriptsForHeadTag = [
  <script
    async
    dangerouslySetInnerHTML={{
      __html: oneLine`
      (function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('//d.impactradius-event.com/A1878624-1204-418b-a8f9-bc2ee97589081.js','script','ire',document,window);
      `,
    }}
  />,
]
