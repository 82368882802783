import { css } from 'styled-components'
import { linkStyle } from './linkStyle'

export const linkStyleWhite = () => css`
  ${linkStyle}
  color: var(--n0);
  &:hover {
    background-color: var(--quip-link-white-bg);
  }
  &:focus,
  &:active {
    border-color: var(--n0);
  }
`
