import { createContext } from 'react'
import uniq from 'lodash/uniq'

// Action Types
export const MODAL_ACTION_TYPES = {
  ADD_MODAL: 'ADD_MODAL',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CLOSE_ALL_MODALS: 'CLOSE_ALL_MODALS',
}

// Initial State for all modals
export const INITIAL_STATE = {
  modals: {},
  openedModals: [],
}

export const MODAL_CONTEXT_DEFAULT = {
  ...INITIAL_STATE,
  addModal: () => {},
  openModal: () => {},
  closeModal: () => {},
  closeAllModals: () => {},
  isOpen: () => false,
  hasBeenOpened: () => false,
}

const ModalContext = createContext<ModalsState>(MODAL_CONTEXT_DEFAULT)

export const modalReducer = (state: ModalReducerInterface, action: ModalReducerActions) => {
  switch (action.type) {
    case MODAL_ACTION_TYPES.ADD_MODAL: {
      if (action.modalid) {
        return { ...state, modals: { ...state.modals, [action.modalid]: !!action.open } }
      }
      return state
    }
    case MODAL_ACTION_TYPES.OPEN_MODAL: {
      if (action.modalid) {
        return {
          ...state,
          modals: { ...state.modals, [action.modalid]: true },
          openedModals: uniq([...state.openedModals, action.modalid]),
        }
      }
      return state
    }
    case MODAL_ACTION_TYPES.CLOSE_MODAL: {
      if (action.modalid) {
        return { ...state, modals: { ...state.modals, [action.modalid]: false } }
      }
      return state
    }
    case MODAL_ACTION_TYPES.CLOSE_ALL_MODALS: {
      const newModals = Object.keys(state.modals).reduce((acc: ModalsStates, key: string) => {
        acc[key] = false
        return acc
      }, {})
      return {
        ...state,
        modals: { ...state.modals, ...newModals },
      }
    }
    default:
      return { ...state }
  }
}

export default ModalContext
