import { createSlice } from '@reduxjs/toolkit'
import { CartTransaction } from '../generated/graphql'

// hydrate the store

export const cartTransaction = createSlice({
  name: 'transaction',
  initialState: [] as CartTransaction[],
  reducers: {
    hydrateTransactions: (_state, action) => action.payload,
    updateTransactions: (_state, action) => action.payload,
  },
})

const { actions, reducer } = cartTransaction
export const { hydrateTransactions, updateTransactions } = actions
export default reducer
