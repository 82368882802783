/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomCareAttribution } from '../modules/products/constants'

const productSelectorSlice = createSlice({
  name: 'productSelector',
  initialState: {
    selectedProductsByCategory: null,
    products: null,
    productDiscounts: null,
    pricingObject: {},
    planOption: null,
    productsByCategory: null,
    upsellProductBundles: [],
    quantity: 1,
    gcSenderName: null,
    gcGifteeEmail: null,
    gcGiftMessage: null,
    gcGiftSendDate: null,
    validFormInputs: false,
    starterPriceVariation: null,
    customCareAttribution: CustomCareAttribution.FOR_ME,
    customCareFirstName: null,
    customCareLastName: null,
    customCareEmail: null,
    customCareFormValid: false,
  },
  reducers: {
    // TODO: Strong Type state 💪
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addInitialAlaCartState: (state: any, action: PayloadAction<any>) => {
      const [
        products,
        selectedProductsByCategory,
        laningCategory,
        planOption,
        productsByCategory,
        learnMoreByCategory,
        initialSelectionState,
        productCategoryImages,
        initialStockStatus,
        upsellProductBundles,
      ] = action.payload
      if (!state.productsByCategory) {
        state.products = products
        state.planOption = planOption
        state.selectedProductsByCategory = selectedProductsByCategory
        state.productsByCategory = productsByCategory || {}
        state.laningCategory = laningCategory
        state.selectionState = initialSelectionState
        state.learnMoreByCategory = learnMoreByCategory
        state.laneProducts = true
        state.categoryImages = productCategoryImages
        state.stockStatus = initialStockStatus
        state.upsellProductBundles = upsellProductBundles
      }
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addProducts: (state: any, action: PayloadAction<any>) => {
      state.products = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addProductDiscounts: (state: any, action: PayloadAction<any>) => {
      state.productDiscounts = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addLaningCategory: (state: any, action: PayloadAction<any>) => {
      state.laningCategory = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addSelectedLanedProducts: (state: any, action: PayloadAction<any>) => {
      state.selectedProductsByCategory = action.payload
      return state
    },
    addUpsellProductBundles: (state, action) => {
      state.upsellProductBundles = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addCategoryOrder: (state: any, action: PayloadAction<any>) => {
      state.categoryOrder = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addUpsellCategories: (state: any, action: PayloadAction<any>) => {
      state.upsellCategories = {}
      action.payload.forEach((category: string) => {
        state.upsellCategories[category] = true
      })
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateUpsellCategories: (state: any, action: PayloadAction<any>) => {
      state.upsellCategories = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCurrentPlanOption: (state: any, action: PayloadAction<any>) => {
      state.planOption = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addSelectedProductsByCategory: (state: any, action: PayloadAction<any>) => {
      state.selectedProductsByCategory = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addSelectedProduct: (state: any, action: PayloadAction<any>) => {
      const [category, product] = action.payload
      state.selectedProductsByCategory[category] = product
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addProductsByCategory: (state: any, action: PayloadAction<any>) => {
      state.productsByCategory = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addLearnMoreByCategory: (state: any, action: PayloadAction<any>) => {
      state.learnMoreByCategory = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addLearnMore: (state: any, action: PayloadAction<string>) => {
      state.learnMore = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addCategoryLifestyleImages: (state: any, action: PayloadAction<any>) => {
      state.categoryLifestyleImages = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addCategoryDescription: (state: any, action: PayloadAction<any>) => {
      state.categoryDescription = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updatePricing: (state: any, action: PayloadAction<any>) => {
      const [clickHandler, pricing] = action.payload
      if (clickHandler || Object.keys(state.pricingObject).length === 0) {
        state.pricingObject = pricing
      }
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateSelectionState: (state: any, action: PayloadAction<any>) => {
      state.selectionState[action.payload] = !state.selectionState[action.payload]
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    startLaningProducts: (state: any) => {
      state.laneProducts = true
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stopLaningProducts: (state: any) => {
      state.laneProducts = false
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateQuantity: (state: any, action: PayloadAction<any>) => {
      state.quantity = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateStockStatus: (state: any, action: PayloadAction<any>) => {
      state.stockStatus = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setInitialSelectionState: (state: any, action: PayloadAction<any>) => {
      state.selectionState = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateGcValues: (state: any, action: PayloadAction<any>) => {
      const { gcGiftMessage, gcGiftSendDate, gcGifteeEmail, gcSenderName, isValid } = action.payload
      state.gcSenderName = gcSenderName
      state.gcGifteeEmail = gcGifteeEmail
      state.gcGiftMessage = gcGiftMessage
      state.gcGiftSendDate = gcGiftSendDate
      state.validFormInputs = isValid
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateStarterPriceVariation: (state: any, action: PayloadAction<any>) => {
      state.starterPriceVariation = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateCustomCareValues: (state: any, action: PayloadAction<any>) => {
      const { attribution, attrFirstName, attrLastName, attrEmail, isValid } = action.payload

      state.customCareAttribution = attribution
      state.customCareFirstName = attrFirstName
      state.customCareLastName = attrLastName
      state.customCareEmail = attrEmail
      state.validFormInputs = isValid
      return state
    },
  },
})

const { actions, reducer } = productSelectorSlice
export const {
  addInitialAlaCartState,
  addProducts,
  addProductDiscounts,
  addSelectedProductsByCategory,
  addSelectedProduct,
  addProductsByCategory,
  addLearnMoreByCategory,
  addLearnMore,
  addCategoryLifestyleImages,
  addCategoryDescription,
  addUpsellCategories,
  updatePricing,
  setCurrentPlanOption,
  addLaningCategory,
  addSelectedLanedProducts,
  addCategoryOrder,
  updateUpsellCategories,
  updateSelectionState,
  startLaningProducts,
  stopLaningProducts,
  updateQuantity,
  updateStockStatus,
  setInitialSelectionState,
  updateGcValues,
  updateStarterPriceVariation,
  updateCustomCareValues,
  addUpsellProductBundles,
} = actions
export default reducer
