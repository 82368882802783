import React, { useEffect, useState, useMemo, useRef, useContext } from 'react'
import { createPortal } from 'react-dom'
import styled, { ThemeProvider } from 'styled-components'
import FocusLock from 'react-focus-lock'
import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'
import ModalCloseButton from './ModalCloseButton'
import ModalCloseIcon from './ModalCloseIcon'
import ModalSection from './ModalSection'
import ModalImage from './ModalImage'
import ModalTitle from './ModalTitle'
import ModalSubTitle from './ModalSubTitle'
import THEMES from './ModalThemes'
import ModalContext, { ModalProvider } from '../../context/Modal'

const Overlay = styled.div`
  background-color: var(--overlay);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  padding: 3.75rem 0.625rem;
  position: fixed;
  overflow: auto;
  top: 0;
  width: 100%;
  z-index: var(--z-index-modal);
`
const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 39.375rem;
  padding: 0;
  position: relative;
  color: ${({ theme }) => theme.color};
  && {
    div {
      color: ${({ theme }) => theme.color};
    }
  }
`

const ModalComponent = ({ theme, showing, modalid, allowHide, closeModal, children }: ModalComponentProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const handleOverlayClick = (e: React.MouseEvent) => {
    const { target } = e
    if (target instanceof Node && modalRef.current?.contains(target)) return
    if (allowHide) {
      closeModal(modalid)
    }
  }

  const modalChildren = useMemo(
    () =>
      React.Children.map(children, child =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.cloneElement(child as React.ReactElement<any>, {
          modalid,
        })
      ),
    [modalid]
  )

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation()
      closeModal(modalid)
    }
  }

  if (showing) {
    return (
      <ThemeProvider theme={THEMES[theme]}>
        <Overlay onClick={handleOverlayClick} ref={overlayRef} data-testid="modal-overlay">
          <FocusLock autoFocus={false}>
            <ModalContainer
              ref={modalRef}
              aria-label={`${modalid}-modal`}
              aria-modal="true"
              role="dialog"
              tabIndex={-1}
              onKeyDown={handleKeyDown}
            >
              {modalChildren}
            </ModalContainer>
          </FocusLock>
        </Overlay>
      </ThemeProvider>
    )
  }
  return null
}

const Modal = ({ portal = true, showModal, allowHide = true, ...modalProps }: ModalProps) => {
  const { modals, addModal, isOpen, closeModal } = useContext(ModalContext)
  const [showing, setShowing] = useState(isOpen(modalProps.modalid))

  useEffect(() => addModal(modalProps.modalid, showModal), [])

  useEffect(() => setShowing(isOpen(modalProps.modalid)), [modals])

  const MODAL_PROPS = useMemo(
    () => ({
      showing,
      allowHide,
      closeModal,
      ...modalProps,
    }),
    [showing, modalProps]
  )

  if (portal) {
    if (typeof window === 'undefined') return null
    return createPortal(<ModalComponent {...MODAL_PROPS} />, document.body)
  }

  return <ModalComponent {...MODAL_PROPS} />
}

export { ModalProvider, ModalContext }

export default {
  Main: Modal,
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
  CloseButton: ModalCloseButton,
  CloseIcon: ModalCloseIcon,
  Image: ModalImage,
  Title: ModalTitle,
  SubTitle: ModalSubTitle,
  Section: ModalSection,
}
