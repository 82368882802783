/* eslint-disable react/no-danger */
import React, { useEffect } from 'react'
import { oneLine } from 'common-tags'
import useCurrentUser from '../contexts/CurrentUserContext/useCurrentUser'

declare global {
  interface Window {
    gtag: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (...args: any[]): void
    }
  }
}

const GA_PROPERTY_ID = 'UA-55113418-1'
const ADWORDS_CONVERSION_ID = '957299510'

export const disabled = false

export const scriptsForHeadTag = [
  <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_PROPERTY_ID}`} />,
  <script
    dangerouslySetInnerHTML={{
      __html: oneLine`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${GA_PROPERTY_ID}');
    gtag('config', 'AW-${ADWORDS_CONVERSION_ID}');
    `,
    }}
  />,
]

export function DynamicPixel() {
  const { loadingCurrentUser, currentUser } = useCurrentUser()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    if (typeof window.dataLayer.push === 'function' && !loadingCurrentUser) {
      const userProperties =
        currentUser && !currentUser.guest
          ? {
              q_user_email: currentUser.email,
              q_hashed_email: currentUser.sha256HashedEmail,
              q_user_name: currentUser.name,
              user_external_id: currentUser.externalId,
              q_order_count: currentUser.orderCount,
            }
          : {}

      window.dataLayer.push(userProperties)
    }
  }, [loadingCurrentUser])

  return null
}

export function onRouteUpdate({ location }: { location: { pathname: string } }) {
  if (typeof window.gtag === 'function') {
    window.gtag('config', GA_PROPERTY_ID, { page_path: location.pathname })
  }
}
