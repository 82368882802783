import { scrollIntoView } from 'seamless-scroll-polyfill'

// Set the area of the element to scroll to. Defaults to start.
export type AnchorScrollToType = 'start' | 'center' | 'end' | 'nearest'

const scrollToAnchor = (href: string, anchorScrollTo: AnchorScrollToType = 'start') => {
  const elementId = href.match(/#(.+)/)?.[1]
  if (!elementId) return

  setTimeout(() => {
    const targetAnchor: HTMLElement | null = document.getElementById(decodeURI(elementId))
    if (!targetAnchor) return

    scrollIntoView(targetAnchor, {
      block: anchorScrollTo,
      behavior: 'smooth',
    })

    // Transfer the "focus navigation starting point" to the target element.
    // To guarantee success, temporarily assign a tabindex if there isn't one.
    const hasTabIndex = targetAnchor.hasAttribute('tabindex')

    if (!hasTabIndex) {
      targetAnchor.setAttribute('tabindex', '-1')
    }

    targetAnchor.focus()

    if (!hasTabIndex) {
      targetAnchor.removeAttribute('tabindex')
    }
  }, 0)
}

export default scrollToAnchor
