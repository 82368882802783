import React, { useEffect, useState } from 'react'
import { createOptimizelyInstance, fetchUserId } from './client'
import { useGetQueryParam } from '../../hooks/useGetQueryParam'
import { scrubQueryParametersFromUrl } from '../utils/scrubQueryParametersFromUrl'

import { OptimizelyProvider } from '@optimizely/react-sdk'

/*
 * The following interfaces are for Optimizely Web.
 * The code in the OptimizelyClientProvider below uses these to deactivate and then reactivate page A/B tests.
 * This code prevents pop in/flashing of elements that are inserted or moved by Optimizely
 */
interface OptimizelyPage {
  apiName: string
  activationType?: string
}

interface OptimizelyVisitor {
  custom?: Record<string, Record<string, string>>
}

interface OptimizelyPages {
  [key: string]: OptimizelyPage
}
type OptimizelyWebUserAttribute = string | boolean | null
interface OptimizelyWebUserAttributes {
  retailProductId?: OptimizelyWebUserAttribute
  forcedIntoExperiment?: OptimizelyWebUserAttribute
}
interface OptimizelyWebPageActivationOptions {
  initialActivation: boolean
}
interface OptimizelyWindowObject {
  data: Record<string, string>
  get: (arg: string) => Record<string, OptimizelyPages>
  initialized: boolean
  push: (arg: Record<string, OptimizelyWebUserAttributes | string | boolean>) => void
  state: Record<string, object[]>
}

declare global {
  interface Window {
    optimizely?: OptimizelyWindowObject
  }
}

const reactivateOptlyPage = (apiName: string) => {
  if (typeof window === 'undefined' || window.optimizely == null || window.optimizely.initialized !== true) return

  // Force deactivation of the page
  window.optimizely.push({
    type: 'page',
    pageName: apiName,
    isActive: false,
  })

  // Reactivate the page. This will force Optimizely to evaluate the conditions again as if reloading the DOM.
  window.optimizely.push({
    type: 'page',
    pageName: apiName,
  })
}

const reactivateOptlyPages = (options?: OptimizelyWebPageActivationOptions) => {
  if (typeof window === 'undefined' || window.optimizely == null || window.optimizely.initialized !== true) return

  const optlyPages: OptimizelyPages = window.optimizely.get('data').pages || {}
  if (!optlyPages || Object.keys(optlyPages).length === 0) return

  Object.keys(optlyPages).forEach((pageKey: string) => {
    const optlyPage = optlyPages[pageKey]
    // If optlyPage.activationType doesn't exist, the experiment triggers "Immediately"
    // We only want to perform this step on initial activation for experiments that trigger "Immediately"
    if (options?.initialActivation && optlyPage.activationType) return

    reactivateOptlyPage(optlyPage.apiName)
  })
}

const getVisitorCustomAttributes = () => {
  if (typeof window === 'undefined' || window.optimizely == null || window.optimizely.initialized !== true) return null

  const visitor: OptimizelyVisitor = window.optimizely.get('visitor')

  if (visitor && visitor.custom) {
    return Object.values(visitor.custom)
  }

  return null
}

const getVisitorAttribute = (attribute: string) => {
  if (typeof window === 'undefined' || window.optimizely == null || window.optimizely.initialized !== true) return null

  const customAttributes = getVisitorCustomAttributes()

  if (customAttributes) {
    return customAttributes.find(customAttribute => customAttribute.name === attribute) ? true : false
  }

  return false
}

export const pushUserAttributesToOptimizelyWeb = (
  attributes: OptimizelyWebUserAttributes,
  activateImmediately = true
) => {
  if (
    Object.keys(attributes).length === 0 ||
    typeof window === 'undefined' ||
    window.optimizely == null ||
    window.optimizely.initialized !== true
  )
    return

  window.optimizely.push({
    type: 'user',
    attributes: attributes,
  })

  if (activateImmediately) reactivateOptlyPages()
}

export const forceUserIntoOptimizelyExperiment = (experimentId: string, variationId: string) => {
  if (typeof window === 'undefined' || window.optimizely == null || window.optimizely.initialized !== true) return

  window.optimizely.push({
    type: 'bucketVisitor',
    experimentId,
    variationId,
  })

  pushUserAttributesToOptimizelyWeb({ forcedIntoExperiment: 'yes' }, true)

  scrubQueryParametersFromUrl(['quip_exp_id', 'quip_var_id'])
}

export const OptimizelyClientProvider = ({ children }: { children: React.ReactNode }) => {
  const experimentId = useGetQueryParam('quip_exp_id')
  const variationId = useGetQueryParam('quip_var_id')
  const visitorForcedIntoExperiment = getVisitorAttribute('forcedIntoExperiment')

  if (typeof window == 'undefined') {
    return <>{children}</>
  }
  const [optimizelyInstance] = useState(createOptimizelyInstance())

  useEffect(() => {
    if (experimentId && variationId) {
      forceUserIntoOptimizelyExperiment(experimentId, variationId)
    } else if (!visitorForcedIntoExperiment) {
      pushUserAttributesToOptimizelyWeb({ forcedIntoExperiment: 'no' }, true)
    } else {
      reactivateOptlyPages({ initialActivation: true })
    }
  }, [])

  return (
    <OptimizelyProvider optimizely={optimizelyInstance} user={{ id: fetchUserId() }}>
      {children}
    </OptimizelyProvider>
  )
}
