import { createInstance } from '@optimizely/react-sdk'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'

declare global {
  interface Window {
    optimizelyDatafile: string | object | undefined
  }
}

function setCookie(cookieName: string): string {
  const anonymousUserId = uuidv4()
  const cookieValue = `"${anonymousUserId}"`
  Cookies.set(cookieName, cookieValue, { expires: 99999 })
  return cookieValue
}

/**
 * Use Segment's anonymous id cookie. Note that segment puts quotes "" around the id so we must strip them here
 */
export const fetchUserId = (cookieName = 'ajs_anonymous_id') => {
  const cookie = Cookies.get(cookieName) || setCookie(cookieName)

  return cookie.replace(/"/g, '')
}

/**
 * Note that window.optimizelyDatafile is added in ./gatsby-ssr.js
 */
export const createOptimizelyInstance = ({ setUser }: { setUser?: boolean } = {}) => {
  const instance = createInstance({
    datafile: typeof window !== 'undefined' ? window.optimizelyDatafile : undefined,
  })

  if (setUser) instance.setUser({ id: fetchUserId() })

  return instance
}
