/* eslint-disable react/no-danger */

import React from 'react'

import { withAssetPrefix } from 'gatsby'

export const disabled = false

export const scriptsForHeadTag = [
  <script
    dangerouslySetInnerHTML={{
      __html: `
        window.IGLOO = window.IGLOO || {
          "loader": {
            "version" : "general5"
          },
        };`,
    }}
  />,
  <script async src={withAssetPrefix('/javascripts/iovation.js')} />,
]
