import React, { useMemo } from 'react'
import styled from 'styled-components'

const ModalBodyContainer = styled.div<{ hasSection: boolean }>`
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: ${props => !props.hasSection && '1.25rem'};
  border-radius: 0.625rem;
  &:nth-child(0) {
    border-top-right-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
  }
  &:nth-last-child {
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
  }
`

const ModalBody = ({ children, style = {}, modalid = '' }: ModalBodyProps) => {
  const bodyChildren = useMemo(
    () =>
      React.Children.map(children, child => {
        if (typeof child !== 'string' && !!child) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return React.cloneElement(child as React.ReactElement<any>, {
            modalid,
          })
        }
        return child
      }),
    [modalid]
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasSection = bodyChildren?.some((child: any) => child.type?.name === 'ModalSection') as boolean
  return (
    <ModalBodyContainer style={style} hasSection={hasSection}>
      {bodyChildren}
    </ModalBodyContainer>
  )
}

export default ModalBody
