import React, { useMemo, useReducer } from 'react'
import ModalContext, { INITIAL_STATE, modalReducer, MODAL_ACTION_TYPES } from './context'

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(modalReducer, INITIAL_STATE)

  const addModal = (modalid: string, open: boolean) => {
    dispatch({ type: MODAL_ACTION_TYPES.ADD_MODAL, modalid, open })
  }

  const closeAllModals = () => {
    dispatch({ type: MODAL_ACTION_TYPES.CLOSE_ALL_MODALS })
  }

  const openModal = (modalid: string) => {
    closeAllModals()
    dispatch({ type: MODAL_ACTION_TYPES.OPEN_MODAL, modalid })
  }

  const closeModal = (modalid: string) => {
    dispatch({ type: MODAL_ACTION_TYPES.CLOSE_MODAL, modalid })
  }

  const isOpen = (modalid: string) => state.modals[modalid]

  const hasBeenOpened = (modalid: string) => state.openedModals.includes(modalid)

  const VALUE = useMemo(
    () => ({
      ...state,
      addModal,
      openModal,
      closeModal,
      closeAllModals,
      isOpen,
      hasBeenOpened,
    }),
    [state]
  )

  return <ModalContext.Provider value={VALUE}>{children}</ModalContext.Provider>
}

export default ModalContext

export { ModalProvider }
