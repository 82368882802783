import React, { useContext, useEffect, useState } from 'react'
import useCurrentUser from '../../contexts/CurrentUserContext/useCurrentUser'
import { OptimizelyContext, ReactSDKClient } from '@optimizely/react-sdk'
import { memoize } from 'lodash'
import { trackEvent } from '../../analytics'

/**
 * Add Optimizely A/B experiments here.
 * The format is Record<experimentName, Array<experimentVariation>>
 *
 * Note that first variant is the default variant that users get if the experiment is not running
 * or they are not part of it.
 */
const isInArray = <T, A extends T>(item: T, array: ReadonlyArray<A>): item is A => array.includes(item as A)

/**
 * Fetch the variation of an experiment the user should see.
 * Send tracking events to Segment.
 *
 * Note that this function is cached so that it will exercise just once and after that the result will
 * be fetched from the cache.
 */
export const fetchVariation = memoize(
  async (experimentName: string, variationNames: string[], optimizelyClientInstance: ReactSDKClient, currentUser?) => {
    // Don't run experiments in webdriver tests
    if (process.env.GATSBY_WEBDRIVER_TEST) return variationNames[0]

    const { success } = await optimizelyClientInstance.onReady()

    const variations = variationNames

    if (!success) {
      console.error('Optimizely failed to load.')
      return variations[0]
    }

    // Check for a forced variation
    const params = new URLSearchParams(window.location.search)
    const forcedVariation = (() => {
      if (params.get('opt_exp') === experimentName && isInArray(params.get('opt_var'), variations)) {
        return params.get('opt_var') as string
      }
      return null
    })()

    if (forcedVariation) {
      optimizelyClientInstance.setForcedVariation(experimentName, forcedVariation)
    }

    const userAttributes = {
      guest: currentUser == null ? true : currentUser.guest, // currentUser is null when user is logged out
      email: currentUser?.email || null,
      city: currentUser?.city || null,
      state: currentUser?.state || null,
      userType: currentUser?.userType || null,
      role: currentUser?.role || null,
    }

    const variation = optimizelyClientInstance.activate(experimentName, undefined, userAttributes)

    if (variation === null) return forcedVariation || variations[0]

    /*
      Experiment Enrolled is emitted each load to protect against a user going idle for 30+ minutes and then
      returning to the site. In such a situation, Segment would lose the enrollment and the event would need to
      be repeated again. We emit each page load so those sessions that occur after returning from idle would still
      get flagged as enrolled, without needing to write complex logic to keep our sessions in sync with Segment.

      -- Justin, the Magnificent
    */
    trackEvent('Experiment Enrolled', {
      experiment_id: experimentName,
      variant_id: variation,
    })

    trackEvent('Experiment Viewed', {
      experiment_id: experimentName,
      variant_id: variation,
    })

    return variation
  }
)

/**
 * Activate the experiement and fetch the variant to show the user. Experiment activation events will fire just once
 * however many time this hook is called.
 */
export function useExperiment(experimentName: string, variationNames: string[]) {
  const [variation, setVariation] = useState<string | null>(null)
  const { optimizely } = useContext(OptimizelyContext)
  const { loadingCurrentUser, currentUser } = useCurrentUser()

  useEffect(() => {
    const fetchVariantWhenReady = async () => {
      if (optimizely == null || loadingCurrentUser) return
      setVariation(await fetchVariation(experimentName, variationNames, optimizely, currentUser))
    }
    fetchVariantWhenReady().catch(e => {
      throw e
    })
  }, [optimizely, loadingCurrentUser])

  return {
    variation,
  }
}

/**
 * Note that rendering this component for any variation will activate the experiment
 */
export const ExperimentVariation = ({
  experimentName,
  variation,
  variationNames,
  children,
}: {
  experimentName: string
  variation: string
  variationNames: string[]
  children: React.ReactNode
}) => {
  const { variation: currentlyActiveVariation } = useExperiment(experimentName, variationNames)

  return variation === currentlyActiveVariation ? <>{children}</> : null
}
