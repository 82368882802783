export enum AllBlackConstants {
  HexCode = '#000000',
  BlackToothpasteSku = 'TPL_CC',
}

export enum AllWhiteConstants {
  HexCode = '#FFFFFF',
  WhiteToothpasteSku = 'TPL_AW',
}

export enum BrushHeadSkus {
  OriginalBrushHeadSku = 'BH',
}

export enum PsProductCategories {
  ADULT_SMART_TOOTHBRUSH = 'Adult Smart Toothbrush',
  CHARGER = 'Charger',
  FLOSS = 'Floss',
  FLOSS_PICK = 'Floss Pick',
  KIDS_SMART_TOOTHBRUSH = 'Kids Smart Toothbrush',
  KIDS_TOOTHBRUSH = 'Kids Toothbrush',
  REFRESH_KIT = 'Refresh Kit',
  SMART_MOTOR = 'Smart Motor',
  SMART_TOOTHBRUSH = 'Smart Toothbrush',
  TOOTHBRUSH = 'Toothbrush',
  TOOTHPASTE = 'Toothpaste',
  TRAVEL_COVER = 'Travel Cover',
  TRAVEL_TOOTHPASTE = 'Travel Toothpaste',
  KIDS_TOOTHPASTE = 'Kids Toothpaste',
  ACCESSORY = 'Accessory',
  REFILLABLE_DISPENSER = 'Refillable Dispenser',
  GUM = 'Gum Chicklet',
  SMART_BRUSH_REWARD = 'Smart Brush Reward',
  GIFT_CARD = 'Digital Gift Card',
  BRUSH_HEAD = 'Brush Head',
  ADULT_BRUSH_HEAD = 'Adult Brush Head',
  FLOSS_STRING_REFILL = 'Floss Refill',
  FLOSS_PICK_REFILL = 'Pick Refill',
  MOUTHWASH_DISPENSER = 'Mouthwash Dispenser',
  MOUTHWASH_REFILL = 'Mouthwash Refill',
  WATER_FLOSSER = 'Cordless Rechargeable Water Flosser',
  WATER_FLOSSER_TIP_REFILL = 'Floss Tip Refill',
  RECHARGEABLE_TOOTHBRUSH = 'Rechargeable Brush',
  RECHARGEABLE_SMART_TOOTHBRUSH = 'Rechargeable Smart Brush',
  MINT_DISPENSER = 'Mint Dispenser',
  WHITENING_AND_GUARDS = 'Whitening and Guards',
  IMPRESSION_KIT = 'Impression Kit',
  WHITENING = 'Whitening',
  WHITENING_GEL = 'Whitening Gel',
  WHITENING_TRAY = 'Whitening Tray',
  SPORT_GUARD = 'Sport Guard',
  NIGHT_GUARD = 'Night Guard',
  HARD_NIGHT_GUARD = 'Hard Night Guard',
  HARD_SOFT_NIGHT_GUARD = 'Hard-Soft Night Guard',
}

export enum ProductSets {
  AdultBrush = 'adultSet',
  AdultSmartToothbrush = 'Adult Smart Toothbrush Set',
  KidsBrush = 'kidsSet',
  KidsSmartToothbrush = 'Kids Smart Toothbrush Set',
}

export enum ProductColors {
  AllBlack = 'ALLBLACK',
  AllPink = 'ALLPINK',
  AllPinkSmart = 'ALLPINKSMART',
  AllWhite = 'ALLWHITE',
  AllWhiteSmart = 'ALLWHITESMART',
  AllYellow = 'ALLYELLOW',
  AllYellowSmart = 'ALLYELLOWSMART',
  Black = 'BLACK',
  Colorless = '',
  Gray = 'GRAY',
  Green = 'GREEN',
  Blue = 'BLUE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Mint = 'MINT',
  Red = 'RED',
  Silver = 'SILVER',
  Slate = 'SLATE',
  Gold = 'GOLD',
  Yellow = 'ALLYELLOWRUBBER',
  White = 'WHITE',
  Original = 'ORIGINAL',
}

export enum ProductAges {
  Adult = 'ADULT',
  Kid = 'KID',
}

export enum ProductMaterials {
  Plastic = 'PLASTIC',
  Rubber = 'RUBBER',
}

export enum PlanOptions {
  REFILL = 'refill',
  REFILL_LATER = 'refillLater',
  ONE_TIME = 'onetime',
  PREPAY = 'prepay',
}

export enum DefaultPlanOptions {
  REFILL = 'Refill Now',
  REFILL_LATER = 'Refill In 3 Months',
  ONE_TIME = 'One Time Purchase',
}

export enum CustomCareAttribution {
  FOR_ME = 'for_me',
  FOR_SOMEONE_ELSE = 'for_someone_else',
}
