import {
  ButtonStyleProps,
  buttonStyle,
  linkStyle,
  linkStyleBlack,
  linkStyleBoldWhite,
  linkStyleGrey,
  linkStyleSage,
  linkStyleWhite,
} from '../../css'

import { MouseEvent } from 'react'
import styled from 'styled-components'

function cssForButtonStyleType(props: ButtonStyleProps) {
  const linkStyleType = props.linkStyleType
  switch (linkStyleType) {
    case null:
      return linkStyle()
    case 'text':
      return linkStyle()
    case 'sageText':
      return linkStyleSage()
    case 'lightGreyText':
      return linkStyleGrey()
    case 'blackText':
      return linkStyleBlack()
    case 'boldWhite':
      return linkStyleBoldWhite()
    case 'whiteText':
      return linkStyleWhite()
    case 'primaryButton':
      return buttonStyle({})
    case 'secondaryButton':
      return buttonStyle({ ...props, ...{ secondary: true } })
    default:
      return buttonStyle(props)
  }
}

export interface ButtonProps extends ButtonStyleProps {
  onClick?: (event?: MouseEvent<HTMLElement>) => void
}

const Button = styled.button<ButtonProps>`
  ${props => cssForButtonStyleType(props)}
`

export default Button
