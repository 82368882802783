import React, { createContext, useContext } from 'react'
import { useLocation } from '@reach/router'
import { trackEvent, GenericTrackEvent } from './events'

/*
  API's for all Quips tracking needs
*/

export type EventTrackerProperties = Omit<GenericTrackEvent, 'targetLocation' | 'targetText'> & {
  targetText?: React.ReactNode | string
}

export const TrackingLocationContext = createContext<string>('')

/**
 * Accepts a react node/fragment as input and returns the text content of the node as a string
 *
 * Example:
 *   node: <div>A <span className="bold">bold</span> link</div>
 *
 * Returns:
 *   A bold link
 */
export const textContent = ({ node }: { node: React.ReactNode | string }) => {
  if (typeof node === 'string') return node.trim()
  if (!React.isValidElement(node)) return ''
  if (!node.props.children) return ''
  if (typeof node.props.children === 'string') return node.props.children.trim()

  return node.props.children
    .map((child: React.ReactNode) => textContent({ node: child }))
    .filter((stringValue: string) => !!stringValue)
    .join(' ')
}

const useDefaultTargetLocation = () => {
  try {
    return useContext(TrackingLocationContext) || useLocation().pathname
  } catch {
    return ''
  }
}

/**
 * targetLocation will use TrackingLocationContext. Note that it's preferable
 * to ensure there is a relevant TrackingLocationContext rather than override this.
 *
 * targetText accepts a string or a ReactNode, is a node is supplied it will extract the text from it.
 */
export const useEventTracker = ({ targetLocation = useDefaultTargetLocation() }: { targetLocation?: string } = {}) => {
  if (targetLocation === '') return (_props: EventTrackerProperties) => {}

  return ({ eventName, targetName, targetType, targetText, targetRef }: EventTrackerProperties) => {
    trackEvent(eventName, {
      targetName,
      targetType,
      targetLocation,
      targetText: textContent({ node: targetText }),
      ...(targetRef ? { targetRef } : null),
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSemanticTracker = ({ type, properties }: { type: string; properties: Record<string, any> }) => {
  trackEvent(type, properties)
  window.dataLayer = window.dataLayer || []
  const eventName = `Segment: ${type}`
  // `properties` is linked to a data layer variable so it must be nulled-out between calls.
  // Google will continuous append to the data layer variable if not cleared.
  window.dataLayer.push({ properties: null })
  window.dataLayer.push({ event: eventName, properties })
}

export const useLeadTracker = () => trackEvent('Lead', {})
