import React, { useMemo } from 'react'
import styled from 'styled-components'

const ModalTitleContainer = styled.h2``

const ModalTitle = ({ children, style = {}, modalid = '' }: ModalTitleProps) => {
  const titleChildren = useMemo(
    () =>
      React.Children.map(children, child => {
        if (typeof child !== 'string' && !!child) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return React.cloneElement(child as React.ReactElement<any>, {
            modalid,
          })
        }
        return child
      }),
    [modalid]
  )
  return <ModalTitleContainer style={style}>{titleChildren}</ModalTitleContainer>
}

export default ModalTitle
