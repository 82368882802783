import React, { FC, useContext } from 'react'
import styled from 'styled-components'
import ModalContext from '../../context/Modal'
import IconClose from '../../../images/icon-close.svg'

const CloseButton = styled.button`
  width: 2.813rem;
  height: 2.813rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  box-shadow: none;
  cursor: pointer;
`

const CloseIcon = styled(IconClose)`
  g {
    stroke: ${({ theme }) => theme.color};
  }
`

const ModalCloseIcon: FC<{ modalid?: string }> = ({ modalid = '' }) => {
  const { closeModal } = useContext(ModalContext)
  const handleCloseModal = () => {
    closeModal(modalid)
  }

  return (
    <CloseButton data-event-action="modalCloseButton" onClick={handleCloseModal} aria-label="Close">
      <CloseIcon />
    </CloseButton>
  )
}

export default ModalCloseIcon
