import { LinkStyleType } from '../elements/Links'
import { css } from 'styled-components'

export interface ButtonStyleProps {
  secondary?: boolean
  buttonColor?: string
  buttonTextColor?: string
  width?: string
  lineHeight?: string
  darkBackground?: boolean
  accountSettingsButton?: boolean /** Temp style until accounts pages are migrated to incisor */
  padding?: string
  linkStyleType?: LinkStyleType
}

const secondaryStyle = ({ darkBackground = false }: ButtonStyleProps) => css`
  background: transparent;
  border-color: var(${darkBackground ? '--n200' : '--quip-sage'});
  ${darkBackground && 'color: var(--n200)'};

  &:hover,
  &:focus,
  &:active {
    ${darkBackground && 'box-shadow: var(--shadow-n200-solid)'};
    ${darkBackground && 'border-color: var(--n200)'};
  }
`

const primaryStyle = ({ darkBackground = false, accountSettingsButton = false }: ButtonStyleProps) => css`
  ${darkBackground && 'background: var(--quip-button-ultra-light-grey)'};
  ${darkBackground && 'border-color: var(--quip-button-ultra-light-grey)'};

  ${accountSettingsButton && 'background: var(--quip-button-moss)'};
  ${accountSettingsButton && 'border-color: var(--quip-button-moss)'};
  ${accountSettingsButton && 'color: var(--n200)'};

  @media (hover: hover) {
    &:hover,
    &:focus,
    &:active {
      border-color: var(${darkBackground ? '--quip-button-moss' : '--quip-sage'});

      ${darkBackground && 'box-shadow: var(--shadow-moss-solid)'};

      ${accountSettingsButton && 'border-color: var(--moss)'};
      ${accountSettingsButton && 'box-shadow: var(--moss)'};
      ${accountSettingsButton && 'background-color: var(--moss)'};
    }
  }
`

export const buttonStyle = ({
  secondary,
  width,
  lineHeight,
  darkBackground = false,
  accountSettingsButton = false,
  padding,
}: ButtonStyleProps) => css`
  display: inline-block;
  background: ${'var(--quip-teal, #16B79D)'};
  color: var(--quip-sage, #0b413e);
  letter-spacing: 0;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0 3.4375em;
  ${padding && `padding: ${padding};`}
  border: 1px solid var(--quip-teal);
  border-radius: 1.75em;
  font-family: var(--font-family, 'Poppins', Helvetica, Arial, sans-serif) !important;
  font-size: var(--font-size-l, 1em);
  font-weight: var(--font-weight-bold, 600);
  line-height: var(--line-height-button, 3.375em);
  ${lineHeight && `line-height: ${lineHeight};`}
  text-align: center;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  width: fit-content;
  ${width && `width: ${width};`}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &[disabled],
  &[aria-disabled='true'] {
    color: var(--input-color-placeholder, #bfbfbf);
    border-color: var(--n200, #f2f2f2);
    background: var(--disabled, #f9f9f9);
    cursor: disable;
    pointer-events: none;

    & svg,
    path {
      fill: var(--input-color-placeholder);
    }
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: var(--shadow-sage-solid);
      outline: none;
    }
  }

  &:focus,
  &:active {
    box-shadow: var(--shadow-sage-solid);
    outline: none;
  }

  ${secondary ? secondaryStyle({ darkBackground }) : primaryStyle({ darkBackground, accountSettingsButton })}

  @media (max-width: 40em) {
    line-height: var(--line-height-button-mobile, 2.375em);
  }
`
