import React, { FC, useMemo } from 'react'
import styled from 'styled-components'

const StyledModalSection = styled.section<{ center: boolean; color?: string }>`
  background-color: ${props => props.color || props.theme.sectionColor || props.theme.backgroundcolor};
  padding: 1.25rem;
  text-align: ${props => props.center && 'center'};
`
const ModalSection: FC<ModalSectionProps> = ({ children, style = {}, modalid = '', center = true, color }) => {
  const sectionChildren = useMemo(
    () =>
      React.Children.map(children, child => {
        if (typeof child !== 'string' && !!child) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return React.cloneElement(child as React.ReactElement<any>, {
            modalid,
          })
        }
        return child
      }),
    [modalid]
  )

  return (
    <StyledModalSection style={style} center={center} color={color}>
      {sectionChildren}
    </StyledModalSection>
  )
}

export default ModalSection
