import { css } from 'styled-components'

export const linkStyle = () => css`
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
  color: var(--quip-link-blue);
  text-decoration: underline;
  &:hover {
    background-color: var(--quip-link-blue-bg);
    border-radius: 1px;
    border: 1px solid transparent;
    text-decoration: none;
  }
  &:focus,
  &:active {
    background-color: transparent;
    border-radius: 1px;
    border: 1px solid var(--quip-link-blue);
    text-decoration: none;
  }
  &[disabled] {
    color: var(--disabled, #f9f9f9);
    pointer-events: none;
  }
`
