import { css } from 'styled-components'
import { linkStyle } from './linkStyle'

export const linkStyleSage = () => css`
  ${linkStyle}
  color: var(--quip-sage);
  &:hover {
    background-color: var(--quip-link-sage-bg);
  }
  &:focus,
  &:active {
    border-color: var(--quip-sage);
  }
`
