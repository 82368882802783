import 'cross-fetch/polyfill'

import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client'

import { InMemoryCache } from '@apollo/client/cache'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { setContext } from '@apollo/client/link/context'
import { sha256 } from 'crypto-hash'

const getCookie = () => {
  const name = 'csrf-token='
  const ca = decodeURIComponent(document.cookie).split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const cache = new InMemoryCache({
  typePolicies: {
    IterableUser: {
      keyFields: ['email'],
    },
    Coupon: {
      keyFields: ['code'],
    },
  },
})

const httpLink = createPersistedQueryLink({ sha256 }).concat(
  createHttpLink({
    uri: '/graphql',
    credentials: 'include',
    useGETForQueries: true,
    fetch,
  })
)

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-CSRF-Token': getCookie(),
    },
  })
  return forward(operation)
})

let preflightInProgress = false
const startSession = setContext((_, previousContext) => {
  if (getCookie() || preflightInProgress) {
    return previousContext
  }

  preflightInProgress = true
  return fetch(`${process.env.GATSBY_SITE_URL || 'https://www.getquip.com'}/api/v1/start_session`, {
    credentials: 'same-origin',
  }).finally(() => {
    preflightInProgress = false
    previousContext
  })
})

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([startSession, authLink, httpLink]),
})
