import { createSlice } from '@reduxjs/toolkit'

// hydrate the store

type PricingObject = {
  id: number
  flatDiscount: number
  discount: number
  bundleDiscount: number
}

export const selectedProductsPricing = createSlice({
  name: 'selectedProductsPricing',
  initialState: [] as PricingObject[],
  reducers: {
    updateSelectedPricing: (_state, action) => action.payload,
  },
})

const { actions, reducer } = selectedProductsPricing
export const { updateSelectedPricing } = actions
export default reducer
