/* eslint-disable react/no-danger */
import React from 'react'
import { oneLine } from 'common-tags'

export const disabled = false

function scriptForCurrentEnv() {
  const activeEnv = process.env.GATSBY_ACTIVE_ENV
  let audioEyeSiteHash

  if (activeEnv === 'production') {
    audioEyeSiteHash = 'bafb26d1f3f242aac0eaff8e9716d332'
  } else if (activeEnv === 'staging') {
    audioEyeSiteHash = 'ca9142a07020333d5c6b04aae35837e1'
  } else {
    return []
  }

  return [
    <script
      async
      dangerouslySetInnerHTML={{
        __html: oneLine`
        !function(){var b=function(){window.__AudioEyeSiteHash = "${audioEyeSiteHash}"; var a=document.createElement("script");a.src="https://wsmcdn.audioeye.com/aem.js";a.type="text/javascript";a.setAttribute("async","");document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();
        `,
      }}
    />,
  ]
}

export const scriptsForBodyTag = scriptForCurrentEnv()
