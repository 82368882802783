/* eslint-disable react/no-danger */

import React from 'react'
import { oneLine } from 'common-tags'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uetq: any[]
  }
}

export const disabled = false

export const scriptsForHeadTag = [
  <script
    async
    dangerouslySetInnerHTML={{
      __html: oneLine`
      (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"5164178"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
      `,
    }}
  />,
  <noscript>
    <img
      src="//bat.bing.com/action/0?ti=5164178&Ver=2"
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
      alt=""
    />
  </noscript>,
]

export function onRouteUpdate({ location }: { location: { pathname: string } }) {
  window.uetq = window.uetq || []
  window.uetq.push('event', 'page_view', { page_path: location.pathname })
}
