/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { ALL_STEPS } from '../components/RefillWizard/useSteps'

const initialState = {
  steps: Object.values(ALL_STEPS),
  ageStep: '',
  colorStep: '',
  couponStep: '',
  currentStepIndex: 0,
  productsStep: [],
  purchaseLocationStep: '',
  refillStartStep: '',
} as RefillWizard

export const refillWizard = createSlice({
  name: 'refillWizard',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setStepsRedux: (state: any, action) => {
      state.steps = action.payload.steps
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateRefillWizard: (state: any, action) => {
      const currentIndex = state.steps.indexOf(action.payload.step)
      for (let idx = currentIndex; idx < state.steps.length; idx += 1) {
        if (idx === currentIndex) {
          state[action.payload.step as RefillWizardStep] = action.payload.answer
        } else {
          const stepName = state.steps[idx] as RefillWizardStep
          const emptyState = stepName === ALL_STEPS.PRODUCTS ? ([] as string[]) : ('' as string)
          state[stepName] = emptyState
        }
      }
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    increaseRefillWizardStepIndex: (state: any) => {
      state.currentStepIndex += 1
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    decreaseRefillWizardStepIndex: (state: any) => {
      state.currentStepIndex -= 1
      return state
    },
  },
})

const { actions, reducer } = refillWizard
export const { updateRefillWizard, decreaseRefillWizardStepIndex, increaseRefillWizardStepIndex, setStepsRedux } =
  actions
export default reducer
