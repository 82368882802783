declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[]
  }
}

/**
 * GenericTrackEvent: Quip's Segment event schema.
 * https://getquip.atlassian.net/wiki/spaces/DATA/pages/699596811/Segment+Tracking+Spec#Track-Events}
 */
export interface GenericTrackEvent {
  eventName: 'Clicked' | 'Tapped' | 'MouseOver' | 'Scrolled' | 'Popup Viewed'
  targetName: string
  targetType: 'Link' | 'ImageLink' | 'Button' | 'Navigation' | 'Overlay' | 'Other'
  targetLocation: string
  targetText?: string
  targetRef?: string
}

type OptionalString = string | null | undefined

/*
  Wrappers around the Segment SDK
*/

export const trackEvent = (eventName: string, properties: Record<string, string>) => {
  // window.analytics comes from Segment script
  window.analytics?.track(eventName, properties)
}

export const identify = (userId: OptionalString, properties: Record<string, OptionalString>) => {
  if (userId) {
    window.analytics?.identify(userId, properties)
  }
}

export const pageView = () => window.analytics?.page()

/*
  Named events, specific to individual use cases.
*/

export const productPageViewEvent = () => {
  trackEvent('productPageViewed', {})
}
